<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 년도 -->
          <c-datepicker
            :range="true"
            defaultStart="-2y"
            defaultEnd="0y"
            type="year"
            label="LBLYEAR"
            name="years"
            v-model="searchParam.years"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 발급부서 -->
          <c-dept type="search" label="LBL0000203" name="issuedDeptCd" v-model="searchParam.issuedDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 단위공정 -->
          <c-process
            label="LBL0000222"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 진행단계 -->
          <c-select
            type="search"
            stepperGrpCd="SWP_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="swpStepCd"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.swpStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 작업허가서 년도별 통계 -->
    <c-table
      title="년도별 작업허가서 작업(허가/보충작업) 통계"
      tableId="swp01"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <q-btn
          v-if="props.row[col.name]"
          class="tableinnerBtn"
          :class="{'tableinnerBtn-left': col.align === 'left' ? true : false,  'tableinnerBtn-right': col.align === 'right' ? true : false}"
          flat
          :align="col.align"
          color="blue-6"
          @click.stop="linkClick(props.row, col)">
          <template v-slot>
            {{props.row[col.name] | toThousandFilter}}
          </template>
        </q-btn>
        <template v-else>0</template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safe-work-permit-state',
  data() {
    return {
      searchParam: {
        plantCd: null,
        years: [],
        issuedDeptCd: null,
        processCd: null,
        swpStepCd: null,
      },
      searchedParam: {
        plantCd: null,
        years: [],
        issuedDeptCd: null,
        processCd: null,
        swpStepCd: null,
      },
      grid: {
        columns: [
          {
            name: 'workYear',
            field: 'workYear',
            // 구분
            label: 'LBL0010059',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'fire',
            field: 'fire',
            // 화기
            label: 'LBL0000219',
            align: 'right',
            sortable: true,
            type: 'custom',
            style: 'width:100px',
          },
          {
            name: 'normal',
            field: 'normal',
            // 일반
            label: 'LBL0000008',
            align: 'right',
            sortable: true,
            type: 'custom',
            style: 'width:100px',
          },
          {
            name: 'sub',
            field: 'sub',
            label: 'LBL0000205',
            child: [
              {
                name: 'subSum',
                field: 'subSum',
                // 소계
                label: 'LBL0000016',
                align: 'right',
                sortable: true,
                type: 'custom',
                style: 'width:100px',
              },
              {
                name: 'confinedSpace',
                field: 'confinedSpace',
                // 밀폐
                label: 'LBL0000017',
                align: 'right',
                sortable: true,
                type: 'custom',
                style: 'width:100px',
              },
              {
                name: 'blackout',
                field: 'blackout',
                // 정전
                label: 'LBL0000279',
                align: 'right',
                sortable: true,
                type: 'custom',
                style: 'width:100px',
              },
              {
                name: 'radiation',
                field: 'radiation',
                // 방사선
                label: 'LBL0000288',
                align: 'right',
                sortable: true,
                type: 'custom',
                style: 'width:100px',
              },
              {
                name: 'excavation',
                field: 'excavation',
                // 굴착
                label: 'LBL0000277',
                align: 'right',
                sortable: true,
                type: 'custom',
                style: 'width:100px',
              },
              {
                name: 'highPlace',
                field: 'highPlace',
                // 고소
                label: 'LBL0000278',
                align: 'right',
                sortable: true,
                type: 'custom',
                style: 'width:100px',
              },
              {
                name: 'heavyEquipment',
                field: 'heavyEquipment',
                // 중장비
                label: 'LBL0000259',
                align: 'right',
                sortable: true,
                type: 'custom',
                style: 'width:100px',
              },
            ]
          },
          {
            name: 'totalSum',
            field: 'totalSum',
            // 총계
            label: 'LBL0000018',
            align: 'right',
            sortable: true,
            type: 'custom',
            style: 'width:100px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: false,
        width: '70%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.workPermit.state.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      if (this.searchParam.years 
        && this.searchParam.years.length > 0 
        && !this.searchParam.years[0]) {
        this.$set(this.searchParam, 'years', [])
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        this.$_.extend(this.searchedParam, this.searchParam)
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      // SPT0000001	일반
      // SPT0000005	화기
      let permitTypeCd = '';
      let supplementWorkTypeCd = '';
      switch(col.name) {
        case 'fire': // 화기
          permitTypeCd = 'SPT0000005';
          break;
        case 'normal': // 일반
          permitTypeCd = 'SPT0000001';
          break;
        case 'confinedSpace': // 밀폐
          supplementWorkTypeCd = 'SSWT000001';
          break;
        case 'blackout': // 정전
          supplementWorkTypeCd = 'SSWT000005';
          break;
        case 'radiation': // 방사선
          supplementWorkTypeCd = 'SSWT000010';
          break;
        case 'excavation': // 굴착
          supplementWorkTypeCd = 'SSWT000015';
          break;
        case 'highPlace': // 고소
          supplementWorkTypeCd = 'SSWT000020';
          break;
        case 'heavyEquipment': // 중장비
          supplementWorkTypeCd = 'SSWT000025';
          break;
        default:
          permitTypeCd = '';
          supplementWorkTypeCd = '';
          break;
      }
      this.popupOptions.title = 'LBL0000019'; // 작업허가서 통계 상세
      this.popupOptions.param = {
        year: row.workYear,
        plantCd: this.searchedParam.plantCd,
        issuedDeptCd: this.searchedParam.issuedDeptCd,
        processCd: this.searchedParam.processCd,
        swpStepCd: this.searchedParam.swpStepCd,
        permitTypeCd: permitTypeCd,
        supplementWorkTypeCd: supplementWorkTypeCd,
      };
      this.popupOptions.target = () => import(`${'./safeWorkPermitStateDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
